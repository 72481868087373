import React from 'react'
import PickMeals from '../Assets/pick-meals-image.png'
import ChooseMeals from '../Assets/choose-image.png'
import DeliveryMeals from '../Assets/delivery-image.png'

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      titile: 'Pick Meals',
      text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur minima assumenda incidunt, nulla ratione delectus.',
    },
    {
      image: ChooseMeals,
      titile: 'Choose How Often',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores.',
    },
    {
      image: DeliveryMeals,
      titile: 'Fast Deliveries',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, eaque repellat.',
    },
  ]
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum, ad
          quo possimus adipisci ab nostrum, labore magnam praesentium voluptates
          perferendis earum.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data, index) => (
          <div className="work-section-info" key={index}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Work
